<script setup lang="ts">
    import RadioCard from '@/components/form-components/RadioCard.vue';
    import { PaymentMethod } from '@containex/portal-backend-dto';
    import { ref, type Ref } from 'vue';
    import { useCompanyQuery } from '@/composables/company';
    import CheckoutSectionCard from '@/checkout/components/common/CheckoutSectionCard.vue';
    import { useI18n } from 'vue-i18n';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';

    const emits = defineEmits<{
        selectPaymentMethod: [PaymentMethod];
    }>();

    const { t } = useI18n();
    const { shouldShowOnAccountOption } = useCompanyQuery();
    const defaultValue = shouldShowOnAccountOption.value ? PaymentMethod.OnAccount : PaymentMethod.Prepayment;
    const paymentMethod: Ref<PaymentMethod> = ref(defaultValue);

    function selectPaymentMethod(method: PaymentMethod): void {
        paymentMethod.value = method;
        emits('selectPaymentMethod', method);
    }

    function setDefaultPaymentMethod(): void {
        paymentMethod.value = defaultValue;
        emits('selectPaymentMethod', defaultValue);
    }

    setDefaultPaymentMethod();
</script>

<template>
    <CheckoutSectionCard>
        <template #header>{{ t('CHECKOUT.PAYMENT.TITLE') }}</template>
        <template #content>
            <div>
                <RadioCard
                    v-if="shouldShowOnAccountOption"
                    name="payment-method"
                    class="card"
                    :model-value="paymentMethod"
                    :value="PaymentMethod.OnAccount"
                    @update:model-value="selectPaymentMethod($event)"
                >
                    <template #header>
                        <div class="card-header">
                            <h4>{{ t('CHECKOUT.PAYMENT.ON_ACCOUNT.TITLE') }}</h4>
                            <IconButtonWithOverlayPanel class="payment-info-icon">
                                <template #icon>
                                    <span class="pi pi-info-circle"></span>
                                </template>
                                <template #overlay-panel-content>
                                    <div class="panel-content">{{ t('CHECKOUT.PAYMENT.ON_ACCOUNT.INFO') }}</div>
                                </template>
                            </IconButtonWithOverlayPanel>
                        </div>
                    </template>
                    <template v-if="paymentMethod === PaymentMethod.OnAccount" #content>
                        <div>{{ t('CHECKOUT.PAYMENT.ON_ACCOUNT.NOTE') }}</div>
                    </template>
                </RadioCard>
                <RadioCard
                    name="payment-method"
                    class="card"
                    :model-value="paymentMethod"
                    :value="PaymentMethod.Prepayment"
                    :disabled="!shouldShowOnAccountOption"
                    @update:model-value="selectPaymentMethod($event)"
                >
                    <template #header>
                        <h4 class="title">
                            {{ t('CHECKOUT.PAYMENT.PREPAYMENT.TITLE') }}
                        </h4>
                    </template>
                    <template v-if="paymentMethod === PaymentMethod.Prepayment" #content>
                        <div>{{ t('CHECKOUT.PAYMENT.PREPAYMENT.NOTE') }}</div>
                    </template>
                </RadioCard>
                <p>{{ t('CHECKOUT.PAYMENT.INFO') }}</p>
            </div>
        </template>
    </CheckoutSectionCard>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .card {
        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .payment-info-icon {
        color: main.$ctx-primary-color;

        &:hover {
            border: 0 none;
            border-radius: 50%;
            background-color: main.$color-background-bluegray;
        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .panel-content {
        max-width: 60ch;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
    }
</style>
